import React from "react"

function EventsDivider() {

    return(
        <div id="eventsDivider"></div>
    )

}

export default EventsDivider